.ant-btn-primary {
  background: red !important;
  border-color: red !important;
  font-weight: bold;
}

.ant-btn-ghost {
  font-weight: bold;
  font-size: 14px;
}


.ant-menu-item, .ant-menu-submenu-title {
  position: relative;
  display: block;
  margin-right: -15px;
  padding: 3px 13px;
  white-space: nowrap;
  cursor: pointer;
  transition: border-color 0.3s, background 0.3s, padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-btn-primary[disabled] {
  background: #ddd !important;
  border-color: #ccc !important;
}

.ant-card-head {
  background: #ddd !important;
}

.ant-card-head-title {
  color: #666 !important;
}

.ant-form-item-label label {
  color: #666;
  font-size: 0.8em;
  font-weight: bold;
}

.ant-layout-header {
  background: white !important;
}

.ant-menu-item-active {
  color: red !important;
  border-bottom-color: red !important;
}

.ant-modal-header {
  background-color: red !important;
}

.ant-modal-close-icon {
  color: white !important;
}

.ant-modal-title {
  color: white !important;
}

.ant-tabs-card-bar {
  margin-bottom: 0 !important;
}

.ant-tabs-tab-active, .ant-tabs-tab:hover {
  color: red !important;
}

.ant-list-item {
  cursor: pointer;
}