@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');
@import '~antd/dist/antd.css';
@import './antd-overrides.css';

body, #root, .full-height {
  height: 100%;
  font-family: 'Roboto';
}

.absolute-center {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.product-oos {
  background-color: #f3cece;
}
